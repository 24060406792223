var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('centered-column-layout',[_c('page-title',{attrs:{"tags":_vm.getTagsByFieldKey('referral_system')},scopedSlots:_vm._u([(!_vm.getTagsByFieldKey('referral_system'))?{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":function($event){return _vm.$store.dispatch('saveChatConfig')}}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")])]},proxy:true}:null],null,true)}),(!_vm.getTagsByFieldKey('referral_system'))?_c('a-card',[_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'referral_private_message',
          'placeholders': _vm.REFERRAL_PLACEHOLDERS,
          'targetToUpload': function () { return _vm.groupUploadTarget; },
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
        },
      }}}),(_vm.isTriggerSet('referral_private_trigger'))?_c('div',{staticClass:"relative"},[_c('select-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'referral_private_trigger',
            'hasAnchor': true,
          }
        }}}),_c('sup',{staticClass:"absolute",staticStyle:{"left":"5px","top":"-10px"}},[_c('tags',{attrs:{"tags":[{ text: _vm.$t('field_will_be_removed_soon'),
                    color: 'rgba(var(--a-danger),1)' }]}})],1),_c('select-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'referral_private_trigger_new',
          }
        }}})],1):_c('select-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'referral_private_trigger_new',
          'hasAnchor': true,
        }
      }}}),(_vm.isTriggerSet('referral_first_private_trigger'))?_c('div',{staticClass:"relative"},[_c('select-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'referral_first_private_trigger',
            'hasAnchor': true,
          }
        }}}),_c('sup',{staticClass:"absolute",staticStyle:{"left":"5px","top":"-10px"}},[_c('tags',{attrs:{"tags":[{ text: _vm.$t('field_will_be_removed_soon'),
                    color: 'rgba(var(--a-danger),1)' }]}})],1),_c('select-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'referral_first_private_trigger_new',
            'hasAnchor': true,
          }
        }}})],1):_c('select-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'referral_first_private_trigger_new',
          'hasAnchor': true,
        }
      }}}),(_vm.isTriggerSet('referral_not_first_private_trigger'))?_c('div',{staticClass:"relative"},[_c('select-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'referral_not_first_private_trigger',
            'hasAnchor': true,
          }
        }}}),_c('sup',{staticClass:"absolute",staticStyle:{"left":"5px","top":"-10px"}},[_c('tags',{attrs:{"tags":[{ text: _vm.$t('field_will_be_removed_soon'),
                    color: 'rgba(var(--a-danger),1)' }]}})],1),_c('select-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'referral_not_first_private_trigger_new',
            'hasAnchor': true,
          }
        }}})],1):_c('select-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'referral_not_first_private_trigger_new',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'referral_private_check_user',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'referral_accept_invite',
          'hasAnchor': true,
        }
      }}})],1):_c('div',{staticClass:"relative",staticStyle:{"height":"300px"}},[_c('not-available-options-overlay',{staticClass:"shadow",staticStyle:{"margin":"0 1rem"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }