





























































































































































































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import PageTitle from '@/components/PageTitle.vue'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import { InputSetups } from '../../mixins/input-setups'
import TariffsTagsHelper from '../../mixins/TariffsTagsHelper'
import Placeholders from "@/mixins/placeholders/placeholders";
import CreateTriggerModalMixin from '@/components/CreateTriggerModal/CreateTriggerModalMixin'
import CreateTriggerModal from '@/components/CreateTriggerModal/CreateTriggerModal.vue'

import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    NotAvailableOptionsOverlay,
    CenteredColumnLayout,
    PageTitle,
    CreateTriggerModal
  },
  data() {
    return {
      MediaEditorMode
    }
  }
})
export default class ReferralSystem extends Mixins(Placeholders, UseFields, InputSetups, TariffsTagsHelper, CreateTriggerModalMixin) {

  isTriggerSet(key: string): boolean {
    return this.$store.state.chatState.chat.config[key] && !!this.$store.state.chatState.chat.config[key].length
  }
}
